* {
  margin: 0;
  box-sizing: border-box;
}

menu, h1, h2, h3, h4, ul, ol, li, p, a, span {
  padding: 0;
}

img {
  object-fit: contain;
}

a {
  text-decoration: none;
  color: inherit;
}
