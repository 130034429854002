.page {
  display: flex;
  margin: 0 auto;
  width: 1460px;
  flex-direction: column;
  font-family: var(--font-family-base);
  color: var(--color-base);
  background-color: var(--background-base);
}

.main {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.main__text {
  margin: 0 auto 80px;
  width: 82%;
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  white-space: preserve-breaks;
}
