.map-section {
  display: flex;
  width: 100%;
  margin-bottom: 115px;
}

.map-section__map {
  margin: auto;
  border: none;
}
