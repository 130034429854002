@font-face {
  font-family: 'Source Serif 4';
  font-weight: 400;
  src: url('./fonts/SourceSerif4-Regular.ttf');
}

@font-face {
  font-family: 'Source Serif 4';
  font-weight: 500;
  src: url('./fonts/SourceSerif4-Medium.ttf');
}

@font-face {
  font-family: 'Source Serif 4';
  font-weight: 600;
  src: url('./fonts/SourceSerif4-SemiBold.ttf');
}

@font-face {
  font-family: 'Source Serif 4';
  font-weight: 700;
  src: url('./fonts/SourceSerif4-Bold.ttf');
}
