.house-card {
  display: flex;
  width: 100%;
  max-height: inherit;
  padding: 50px 50px 80px;
  column-gap: 60px;
  background-color: var(--background-base);
  border: 5px solid var(--color-title);
  border-radius: 30px;
}

.house-card__info {
  display: flex;
  width: 710px;
  flex-direction: column;
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  white-space: preserve-breaks;
  overflow-y: auto;
  // scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-secondary);
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-title);
  }
  
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: var(--background-secondary);
  }
}

.house-card__bold-text {
  font-weight: 700;
}
