.banner {
  display: flex;
  width: 100%;
  height: 430px;
  background-image: url('../../images/banner-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner__title {
  margin: auto auto 120px;
  font-size: 90px;
  line-height: 113px;
  font-weight: 700;
  color: var(--background-base);
}
