.total-popup {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.total-popup__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  cursor: pointer;
}

.total-popup__content {
  position: relative;
  margin: auto;
  max-height: 100vh;
}

.total-popup__close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 25px;
  height: 25px;
  object-fit: fill;
  transition-duration: .4s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}
