.legend-point {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
}

.legend-point__image-wrapper {
  display: flex;
  width: 52px;
  height: 48px;
}

.legend-point__image {
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.legend-point__label {
  font-size: 30px;
  line-height: 41px;
  font-weight: 600;
}
